import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Winner from "../../components/winner"
import LeftMenu from './leftMenu'

import "../../components/fontawesome.css"
const Winners = () => (
  <Layout>
    <SEO title="Essay Competition Winners" />

    <div className="subpageContainer">

      <LeftMenu active="winners" />
      <div className="body winners" style={{marginTop: '50px'}}>
        <h2>2020 Essay Competition Winners </h2>
        {/* <a href="https://forms.gle/Wgfcbyych8kuUNwF7" target="_blank">Vote for the essay you think is the best!</a><br /> */}
      

        <h3>UNIVERSITY </h3>

        <div className="winner-group">
          <Winner name="Christopher Melling" school="J. Reuben Clark Law School" photo="Christopher-Melling.jpg" link="WORD_Christopher Melling_J. Rebuen Clark Law School_McCarthey Family Foundation.pdf" />
        </div>
        
        <h3>GRADES 9-12</h3>
        <div className="winner-group">
          <Winner name="Arundhati Oommen" school="West High School, 11th Grade" photo="Dhati-Oommen.png" link="Arundhati Oommen.pdf" />
        </div>
        
        <h3>GRADES 6-8</h3>
        <div class="winner-group">
          <Winner name="Jerry Joseph" school="Draper Park Middle School, 6th Grade" photo="JERRY-JOSEPH.jpeg" link="JerryJoseph.pdf" />
        </div>

      </div>
    </div>

    

  </Layout>
)

export default Winners
