import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import "./components.scss"

const Winner = props => {
  const { name, school, photo = undefined, link = undefined } = props
  return (
    <div className="winner">
      <div className="photo">
        <div dangerouslySetInnerHTML={{__html: `<img src="/essay-winners/${photo}" alt="${name}" />`}} className="winner-photo"></div>
      </div>
      <div className="details">
        <div className="name">{name}</div>
        <div className="school">{school}</div>
        {/* <div className="location">{location}</div> */}
        {(link !== undefined) ? (
        
        <div className="essayLink"><a href={`/essays/pdf/${link}`} target="_blank"><FontAwesomeIcon icon={faPaperclip} /> Read Essay</a></div>
        ): ''}
      </div>
  </div>
    
  )
}

export default Winner